<template>
  <div>
    <el-dialog
      title="编辑计费规则"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="form" label-width="140px" :rules="rules" style="padding: 0">
        <el-form-item :label="$t('searchModule.Vehicle_type')" prop="carType">
          <el-select disabled v-model="form.carType" placeholder="请选择">
            <el-option
              :label="value.desc"
              :value="value.code"
              v-for="value in carType"
              :key="value.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="计费规则" prop="feeId">
          <el-select v-model="form.feeId" placeholder="请选择">
            <el-option
              :label="value.feeName"
              :value="value.feeId"
              v-for="value in feeList"
              :key="value.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否按泊位计费" prop="sceneType">
          <el-radio-group disabled v-model="form.sceneType" @change="carTypeChange">
            <el-radio :label="3">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对应泊位号" v-show="form.sceneType == 3">
          <el-transfer
            :titles="['未选择', '已选择']"
            ref="transfer"
            filterable
            filter-placeholder="请输入内容"
            v-model="form.berthIds"
            :props="{
              key: 'berthId',
              label: 'berthCode',
            }"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="createParkFee">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      id: "",
      form: {
        carType: "",
        feeId: "",
        sceneType: "",
        berthIds: [],
      },
      data2: [],
      carType: [],
      feeList: [],
      rules: {
        carType: {
          required: true,
          message: "必选",
          trigger: "blur",
        },
        feeId: {
          required: true,
          message: "必选",
          trigger: "blur",
        },
        sceneType: {
          required: true,
          message: "必选",
          trigger: "blur",
        },
        berthIds: {
          required: true,
          message: "必选",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    open() {
      this.$refs.form && this.$refs.form.resetFields();
      this.$refs.transfer && this.$refs.transfer.clearQuery("left");
      this.$refs.transfer && this.$refs.transfer.clearQuery("right");
      this.dialogVisible = true;
    },
    carTypeChange(item) {
      if (item == 2) return;
      this.getBerthList();
    },
    createParkFee() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.sceneType == 3 && this.form.berthIds.length) {
            this.form.berthIds = this.form.berthIds.join(",");
          } else if (this.form.sceneType == 3) {
            this.$alert("请选择泊位！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          } else {
            this.form.berthIds = "";
          }
          let url = "/acb/2.0/feeSceneMigrate/update";
          this.$axios
            .post(url, {
              data: {
                parkId: this.$route.query.parkId,
                feeSceneId: this.id,
                ...this.form,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    getCarType() {
      let url = "/acb/2.0/feeScene/carTypeList";
      this.$axios.get(url).then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.carType = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getDetail(id) {
      this.id = id;
      this.berthIds = [];
      let url = "/acb/2.0/feeScene/byId/" + id;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          for (let i in this.form) {
            this.form[i] = res.value[i];
          }
          this.form.berthIds = res.value.berthIdList;
          if (this.form.sceneType == 3) {
            this.getBerthList(res.value.usedBerthList);
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getFeeList() {
      let url = "/acb/2.0/fee/operationId";
      this.$axios
        .get(url, {
          data: {
            operationId: this.$route.query.operationId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.feeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getBerthList(data) {
      if (this.form.carType === "") {
        this.$alert("请选择车型", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.form.sceneType != 3) return;
      let url = "/acb/2.0/berth/byParkIdCarType";
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
            carType: this.form.carType,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (data && data.length) {
              this.data2 = [...res.value, ...data];
            } else {
              this.data2 = res.value;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  created() {
    this.getCarType();
    // this.getFeeList();
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
