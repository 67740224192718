var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看设备",
            visible: _vm.dialogVisible,
            width: "788px",
            "show-close": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                rules: _vm.rules,
                model: _vm.formInline,
                "label-width": "120px",
              },
            },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备名称：", prop: "equipmentType" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "of",
                            attrs: { title: _vm.formInline.equipmentName },
                          },
                          [_vm._v(" " + _vm._s(_vm.formInline.equipmentName))]
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备类型：", prop: "equipmentType" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.formInline.equipmentTypeName) + " "
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备功能：", prop: "traceTag" } },
                      [_vm._v(" " + _vm._s(_vm.formInline.traceTagName) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备编码：", prop: "equipmentCode" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "of",
                            attrs: { title: _vm.formInline.equipmentCode },
                          },
                          [_vm._v(" " + _vm._s(_vm.formInline.equipmentCode))]
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "设备品牌：", prop: "brandId" } },
                      [_vm._v(" " + _vm._s(_vm.formInline.brandName) + " ")]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注：", prop: "description" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "of",
                            attrs: { title: _vm.formInline.description },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.formInline.description) + " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formInline.traceTag !== "",
                      expression: "formInline.traceTag !== ''",
                    },
                  ],
                  attrs: { label: "对应泊位号:", prop: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "listStyleWrapper" },
                    _vm._l(_vm.hasSelectedBerth, function (v) {
                      return _c(
                        "div",
                        { key: v.berthId, staticClass: "listStyle" },
                        [_vm._v(" " + _vm._s(v.berthCode) + " ")]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }