var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "收费员配置",
            visible: _vm.dialogVisible,
            width: "500",
            "close-on-click-modal": false,
            "show-close": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: { model: _vm.form, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.Toll_collector") } },
                [
                  _c("el-transfer", {
                    ref: "transfer",
                    attrs: {
                      "target-order": "push",
                      titles: ["未选择", "已选择"],
                      filterable: "",
                      "filter-placeholder": "请输入...",
                      props: {
                        label: "pdaManagerName",
                        key: "pdaManagerId",
                      },
                      data: _vm.data2,
                    },
                    model: {
                      value: _vm.pdaManagerIds,
                      callback: function ($$v) {
                        _vm.pdaManagerIds = $$v
                      },
                      expression: "pdaManagerIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }