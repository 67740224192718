<template>
  <div>
    <el-dialog
      title="编辑泊位"
      :visible.sync="dialogVisible"
      width="620px"
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="dialog"
    >
      <el-form
        ref="berthform"
        :rules="rules"
        :model="formInline"
        label-width="150px"
        style="padding: 0"
      >
        <el-form-item :label="$t('searchModule.Berth_number')" prop="berthCode">
          <el-input
            disabled
            :maxlength="30"
            v-model="formInline.berthCode"
            class="formWidth"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Service_label')">
          <el-checkbox-group v-model="serviceAttr">
            <el-checkbox label="1">潮汐泊位</el-checkbox>
            <el-checkbox label="2">共享泊位</el-checkbox>
            <el-checkbox label="3">充电泊位</el-checkbox>
            <el-checkbox label="4">残疾人泊位</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Berth_type')" prop="berthType">
          <el-radio-group v-model="formInline.berthType">
            <el-radio
              :label="value.code"
              :key="value.code"
              v-for="value in berthType"
            >{{ value.desc }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="经纬度" prop="longitude">
          <el-input
            :maxlength="20"
            style="width: 220px"
            v-model.trim="lnglat"
            placeholder="在地图上选择坐标点"
          ></el-input>
          <el-button
            type="primary"
            class="map1"
            style="margin-top: 0; margin-right: -12px"
            @click="openMap"
            >地图选点
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitData">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <map-cone
      ref="mapConpent1"
      :lnglat="lnglat"
      @mapCancel="mapCancel"
      @mapSure="mapSure"
    ></map-cone>
  </div>
</template>
<script>
import mapCone from "./map1";
export default {
  data() {
    return {
      lnglat: "",
      serviceAttr: [],
      dialogVisible: false,
      berthId: "",
      formInline: {
        parkId: this.$route.query.parkId,
        berthCode: "",
        batchAddNum: "",
        berthQuality: "",
        berthType: "",
        berthDisability: 0,
        serviceTypeShare: "0",
        serviceTypeTide: "0",
        charge: "0",
        longitude: "",
        latitude: "",
      },
      data2: [],
      rules: {
        berthCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
        berthQuality: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        berthType: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
        berthDisability: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(data) {
      if (data === false) {
        this.lnglat = "";
      }
    },
    lnglat(data) {
      if (data) {
        let arr = data.split(",");
        this.formInline.longitude = Math.round(arr[0] * 1000000);
        this.formInline.latitude = Math.round(arr[1] * 1000000);
      }
    },
  },
  props: ["qualityState", "berthType"],
  components: {
    mapCone,
  },
  methods: {
    openMap() {
      //  let city = this.treeData[0].areaName;
      this.$refs.mapConpent1.openMap(this.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent1.mapClose();
      // this.formInline.parkAddress = '';
      // this.lnglat = '';
    },
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      // this.formInline.parkAddress = opt.parkAddress;
      this.lnglat = opt.hasSelect;
      this.$refs.mapConpent1.mapClose();
    },
    open() {
      this.formInline.berthQuality = "";
      this.serviceAttr = [];
      this.dialogVisible = true;
      this.$refs.berthform && this.$refs.berthform.resetFields();
    },
    getDetail({ berthId }) {
      this.berthId = berthId;
      this.$refs.berthform && this.$refs.berthform.resetFields();
      this.$axios
        .get("/acb/2.0/berth/detail", {
          data: {
            berthId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let lng = res.value.berth.longitude / 1000000 || "";
            let lat = res.value.berth.latitude / 1000000 || "";
            if (lng && lat) {
              this.lnglat = lng + "," + lat;
            } else {
              this.lnglat = "";
            }
            for (let i in this.formInline) {
              this.formInline[i] = res.value.berth[i] || 0;
            }
            if (this.formInline.serviceTypeShare == 1) {
              this.serviceAttr.push("2");
            }
            if (this.formInline.serviceTypeTide == 1) {
              this.serviceAttr.push("1");
            }
            if (this.formInline.charge == 1) {
              this.serviceAttr.push("3");
            }
            if (this.formInline.berthDisability == 1) {
              this.serviceAttr.push("4");
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    submitData() {
      this.$refs.berthform.validate((valid) => {
        var re = /^[0-9]+.?[0-9]*/;
        if (!re.test(this.formInline.longitude) && this.lnglat !== "") {
          this.$alert("请输入正确的经纬度！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        } else if (!re.test(this.formInline.latitude) && this.lnglat !== "") {
          this.$alert("请输入正确的经纬度！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        if (this.lnglat == "") {
          this.formInline.longitude = "";
          this.formInline.latitude = "";
        }
        // console.log(this.formInline.latitude, this.formInline.longitude)
        if (valid) {
          this.formInline.serviceTypeShare = Number(
            this.serviceAttr.some((v) => {
              return v == 2;
            })
          );
          this.formInline.serviceTypeTide = Number(
            this.serviceAttr.some((v) => {
              return v == 1;
            })
          );
          this.formInline.charge = Number(
            this.serviceAttr.some((v) => {
              return v == 3;
            })
          );
          this.formInline.berthDisability = Number(
            this.serviceAttr.some((v) => {
              return v == 4;
            })
          );
          this.$axios
            .post("/acb/2.0/berth/update", {
              data: {
                berthId: this.berthId,
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit("searchData");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
